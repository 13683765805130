<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Lead Source</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Source"
              >
                <v-select
                  v-model="selectedLeadSource"
                  label="name"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leadSourceOption"
                  @input="leadSource"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="display">
            <b-form-group>
              <label>Sub Source</label>
              <v-select
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="selectedSubSource"
                :label="setLabel"
                :options="subSourceOption"
              >
                <template #option="{ fullname, profile_image, username, color }">
                  <b-avatar
                    :src="getprofileImage(profile_image)"
                    v-if="
                      (selectedLeadSource &&
                        selectedLeadSource.name == 'Channel Partner') ||
                      selectedLeadSource.name == 'Employee' ||
                      selectedLeadSource.name == 'Franchise'
                    "
                  />
                  <span :class="'font-weight-bolder' + ' text-' + color">
                    {{ fullname }}
                  </span>

                  <span
                    :class="' text-' + color"
                    v-if="
                      (selectedLeadSource &&
                        selectedLeadSource.name == 'Channel Partner') ||
                      selectedLeadSource.name == 'Employee' ||
                      selectedLeadSource.name == 'Franchise'
                    "
                    >({{ username }})</span
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Client Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input v-model="name" placeholder="Enter Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Surname" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Father/Husband Name </label>
              <b-form-input v-model="fathername" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Lead Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Date"
              >
                <flat-pickr
                  v-model="leadDate"
                  class="form-control"
                  :state="errors.length > 0 ? false : null"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group>
              <label>Next FollowUP Date</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Date"
              >
                <b-form-datepicker
                  v-model="nextfollowupdate"
                  menu-class="w-100"
                  calendar-width="100%"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group>
              <label>Contact Person</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Contact Person"
              >
                <b-form-input
                  v-model="contactPerson"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Contact Person"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Mobile" rules="required">
                <b-form-input
                  v-model="mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <b-form-input v-model="email" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Lead Category</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Category"
              >
                <v-select
                  v-model="leadcategory_id"
                  label="name"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leadCategoryOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Occupation</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Occupation"
              > -->
              <b-form-input v-model="occupation" placeholder="Enter Occupation" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Comapany Name</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Company Name"
              > -->
              <b-form-input v-model="lead_companyname" placeholder="Enter Company Name" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Job Title</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Job Title"
              > -->
              <b-form-input v-model="lead_jobtitle" placeholder="Enter Job Title" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address</label>
              <b-form-input v-model="address" placeholder="Enter Address" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <b-form-input
                v-model="pincode"
                maxlength="6"
                @input="clickPincode($event)"
                type="number"
                placeholder="Enter Pin Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <v-select
                v-model="city"
                :options="cityOption"
                @input="clickArea($event)"
                label="City"
                placeholder="None"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <v-select
                v-model="area"
                :options="areaOption"
                placeholder="None"
                @input="handlePincode()"
                label="PostOfficeName"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <v-select
                v-model="state"
                placeholder="None"
                :options="stateOptions"
                label="State"
                @input="handleCity()"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col md="4">
            <b-form-group>
              <label>Area</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Area">
                <b-form-input v-model="area" :state="errors.length > 0 ? false : null" placeholder="Enter Area"
                   />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="City">
                <b-form-input v-model="city" :state="errors.length > 0 ? false : null" placeholder="Enter City"
                   />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Pin Code" rules="digits:6">
                <b-form-input v-model="pincode" maxlength="6" :state="errors.length > 0 ? false : null" placeholder="Enter Pin Code"
                   />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" re name="State">
                <b-form-input v-model="state" :state="errors.length > 0 ? false : null" placeholder="Enter State"
                   />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Remark</label>
              <validation-provider #default="{ errors }" name="Remark">
                <b-form-input
                  v-model="remark"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Remark"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="role != 'franchise'">
            <b-form-group>
              <label>Select Type</label>
              <v-select
                v-model="selectedType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="assigntoTypeOptions"
                placeholder="None"
                @input="handleAssign()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="selectedType !== 'Self'">
            <b-form-group>
              <label>Assign to</label>
              <v-select
                v-model="selectedAssignTo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="fullname"
                :options="assignToOption"
                placeholder="None"
              >
                <template #option="{ profile_image, username, color, fullname }">
                  <b-avatar
                    :src="getprofileImage(profile_image)"
                    v-if="
                      selectedType == 'Channel Partner' ||
                      selectedType == 'Employee' ||
                      selectedType == 'Franchise'
                    "
                  />
                  <span :class="'font-weight-bolder ' + 'text-' + color">
                    {{ fullname }}
                  </span>
                  <span
                    :class="'text-' + color"
                    v-if="
                      selectedType == 'Channel Partner' ||
                      selectedType == 'Employee' ||
                      selectedType == 'Franchise'
                    "
                    >({{ username }})</span
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="submitDisable"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar,
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedLeadSource: "",
      selectedSubSource: "",
      leadSourceOption: [],
      subSourceOption: [],
      leadValue: "",
      name: "",
      test: "",
      leadDate: moment(new Date()).format("DD/MM/yyyy"),
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      mobile: "",
      email: "",
      address: "",
      area: "",
      state: "",
      stateOptions: [],
      city: "",
      cityOption: [],
      pincode: "",
      leadCategoryOptions: [],
      areaOption: [],
      remark: "",
      selectedAssignTo: "",
      assignToOption: [],
      fromState: "",
      ifEdit: false,
      submitDisable: false,
      selectedType: "",
      role: "",
      userId: "",
      allCp: "",
      cp: "",
      allFr: "",
      leadcategory_id: "",
      assigntoTypeOptions: ["Channel Partner", "Franchise", "Employee", "Self"],
      fr: "",
      userData: "",
      display: true,
      occupation: "",
      lead_companyname: "",
      lead_jobtitle: "",
      type: "",
      otherLeadOption: [],
      cpleadsource: "",
      empleadsource: "",
      selfleadsource: "",
      mastercp: "",
      surname: "",
      fathername: "",
    };
  },
  computed: {
    setLabel() {
      if (
        this.selectedLeadSource &&
        (this.selectedLeadSource.name == "Channel Partner" ||
          this.selectedLeadSource.name == "Franchise" ||
          this.selectedLeadSource.name == "Employee")
      ) {
        return "fullname";
      } else {
        return "name";
      }
    },
  },
  watch: {
    "$store.state.app.all_users": {
      handler: "leadSource",
      immediate: true,
    },
  },
  mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      rights.map((item) => {
        if (item.modulename == "Lead") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/lead");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/lead");
          }
        }
      });
      this.assigntoTypeOptions.splice(1, 1);
    }
    this.userData = this.$store.state.app.user_data;
    this.role = this.userData.role;
    this.userId = this.userData.id;
    this.type = this.userData.type;
    if (this.role == "franchise") {
      this.selectedAssignTo = this.userData;
      this.assignToOption = [this.userData];
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleAssign() {
      this.assignToOption = [];
      if (this.selectedType == "Channel Partner") {
        if (this.userData.role != "employee") {
          this.getAllCp();
        } else {
          this.assignToOption = this.$store.state.app.all_users.filter(
            (item) => item.assignto == this.userData.id && item.role == "channelpartner"
          );
        }
      } else if (this.selectedType == "Franchise") {
        this.getAllFr();
      } else if (this.selectedType == "Self") {
        this.selectedAssignTo = this.userData;
      } else {
        if (this.userData.role != "channelpartner") {
          this.getEmployee();
        } else {
          this.assignToOption = this.userData.assignto;
        }
      }

      if (!this.selectedType) {
        this.assignToOption = [];
      }
    },
    async getAllFr() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (
            this.selectedLeadSource &&
            this.selectedLeadSource.name == "Franchise" &&
            this.role == "admin"
          ) {
            this.subSourceOption = response.data.data;
          }
          if (this.selectedType == "Franchise" && this.role == "admin") {
            this.assignToOption = response.data.data;
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAllCp() {
      this.assignToOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async self() {
      this.assignToOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/cpparentchild`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.selectedAssignTo = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      this.assignToOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/cpparentchild`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (this.role !== "admin") {
            this.assignToOption.push(response.data.data);
          }
          response.data.data.children.map((item) => {
            if (item.role == "channelpartner") {
              this.assignToOption.push(item);
            }
          });
          if (this.role !== "admin" && response.data.data.parent) {
            this.assignToOption.push(response.data.data.parent);
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getTeam(id) {
      this.assignToOption = [];

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getteam`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area?.Pincode;
      }
    },
    onClickBack() {
      this.$router.push("/crm/lead");
    },
    async leadSource() {
      if (this.selectedLeadSource) {
        this.leadValue = this.selectedLeadSource ? this.selectedLeadSource.name : "";
        if (this.leadValue == "Self") {
          this.display = false;
        } else {
          this.display = true;
        }
        this.subSourceOption = [];
        if (this.leadValue == "Channel Partner") {
          if (this.userData.role == "channelpartner") {
            this.getCpDownline();
          } else {
            this.subSourceOption = this.$store.state.app.all_users.filter(
              (item) => item.assignto == this.userData.id
            );
          }
        } else if (this.leadValue == "Franchise") {
          this.getAllFr();
        } else if (this.leadValue == "Employee") {
          if (this.userData.role != "channelpartner") {
            this.getEmployee();
          } else {
            this.subSourceOption = this.userData.assignto;
          }
        } else if (this.leadValue == "Self") {
          this.selectedSubSource = this.userData;
        } else {
          this.subSourceOption = [];
          this.leadSourceOption.map((item) => {
            if (item.parent == this.selectedLeadSource.id) {
              this.subSourceOption.push(item);
            }
          });
        }
      }
    },

    handleCP(id) {
      this.allCp.map((item) => {
        if (item.parent == id) {
          this.cp.push(item);
          this.handleCP(item.id);
        }
      });
    },
    async getCpDownline(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getChannelpartner/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.cp = [];
          this.allCp = json.data.data;
          this.allCp = [this.userData];
          json.data.data.map((item) => {
            if (this.role != "admin" && this.type != "leadmanager") {
              item.color = "success";
            }
            this.allCp.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children, "info");
            }
          });

          if (this.selectedLeadSource.name == "Channel Partner") {
            this.subSourceOption = this.allCp;
          }
          if (this.selectedType == "Channel Partner") {
            this.assignToOption = this.allCp;
          }
          if (id !== undefined) {
            this.subSourceOption.map((item) => {
              if (item.id == id) {
                this.subsource = item;
              }
            });
          }
          if (this.role == "channelpartner") {
            this.setCpAssignto();
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    setCpAssignto() {
      this.assignToOption = [...this.userData.assignto, ...this.assignToOption];
    },
    handleChild(childs, color) {
      childs.map((item) => {
        if (this.role == "admin" || this.type == "leadmanager") {
          item.color = color;
        }
        this.allCp.push(item);
        if (item.children.length > 0) {
          this.handleChild(
            item.children,
            color == "info" && this.role == "admin" ? "success" : ""
          );
        }
      });
    },

    handleFr(id) {
      this.allFr.map((item) => {
        if (item.parent == id) {
          this.fr.push(item);
          this.handleFr(item.id);
        }
      });
    },
    async getFrDownline(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getallfranchise`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.fr = [];
          this.allFr = json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEmployee() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getEmployee`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          if (this.selectedLeadSource.name == "Employee") {
            this.subSourceOption = json.data.data;
          }
          if (this.selectedType == "Employee") {
            this.assignToOption = json.data.data;
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "put",
        url: `${this.baseApi}/lead/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      (this.selectedLeadSource =
        item.leadsource && item.leadsource.length > 0 ? item.leadsource[0] : ""),
        (this.selectedSubSource =
          item.subsource && item.subsource.length > 0 ? item.subsource[0] : ""),
        (this.name = item.name),
        (this.leadDate = moment(item.leaddate).format("DD/MM/yyyy")),
        (this.mobile = item.mobile),
        (this.email = item.email),
        (this.test = item.email),
        (this.address = item.address),
        (this.area = item.area),
        (this.state = item.state),
        (this.city = item.city),
        (this.pincode = item.pincode),
        (this.remark = item.remarks),
        (this.surname = item.surname),
        (this.fathername = item.fathername),
        (this.selectedAssignTo = item.assignto.length > 0 ? item.assignto[0] : "");
      this.getCustomerUser(this.selectedAssignTo);
      this.lead_companyname = item.lead_companyname;
      this.lead_jobtitle = item.lead_jobtitle;
      this.occupation = item.occupation;
      this.leadSource(this.selectedLeadSource);
      this.leadcategory_id = item.leadcategory.length > 0 ? item.leadcategory[0] : "";
      this.handleCity();
      this.handleArea();
      // this.clickPincode()
    },
    async handleSubsource(id) {
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.selectedSubSource = response.data.data[0];
      });
    },
    async getCustomerUser(assignto) {
      if (assignto.role == "channelpartner") {
        this.selectedType = "Channel Partner";
        if (this.userData.role == "channelpartner") {
          this.getCpDownline();
        } else {
          this.assignToOption = this.$store.state.app.all_users.filter(
            (item) => item.assignto == this.userData.id && item.role == "channelpartner"
          );
        }
      } else if (assignto.role == "franchise") {
        this.selectedType = "Franchise";
        this.getFrDownline();
      } else if (assignto.role == "employee") {
        this.selectedType = "Employee";
        if (this.userData.role != "channelpartner") {
          this.getEmployee();
        } else {
          this.assignToOption = this.userData.assignto;
        }
      } else {
        this.selectedType = "Self";
      }
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
    },
    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    reset() {},
    submitForm(e) {
      const data = {
        leaddate: this.leadDate,
        branch: 1,
        company: 1,
        active: 1,
        leadsource: this.selectedLeadSource.id ?? "",
        subsource: this.selectedSubSource ? this.selectedSubSource.id : "",
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        address: this.address,
        area: this.area?.PostOfficeName,
        state: this.state?.State,
        city: this.city?.City,
        pincode: this.pincode,
        remarks: this.remark,
        assignto: this.selectedAssignTo ? this.selectedAssignTo.id : "",
        lead_companyname: this.lead_companyname,
        lead_jobtitle: this.lead_jobtitle,
        occupation: this.occupation,
        surname: this.surname,
        fathername: this.fathername,

        leadcategory_id: this.leadcategory_id.id ?? "",
      };
      if (this.email == this.test) {
        delete data.email;
      }

      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/lead/${this.$route.params.id}`
              : `${baseApi}/lead`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: this.ifEdit ? "Lead Add SuccessFully" : "Lead Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/crm/lead");
            })
            .catch((error) => {
              this.submitDisable = false;

              let email, name, message;
              const code = error.toString().includes("409");
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                message = email + " " + name;
              }
              this.$swal({
                title: "Error!",
                text: code ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async init() {
      this.getLeadCategory();
      this.getLeadSource();
      // this.getAssignSource();
      this.getState();
    },
    async getLeadCategory() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/all-leadcategory`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.leadCategoryOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLeadSource(id, subid) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadsourcedata`,
      };
      await axios(requestOptions)
        .then((response) => {
          // this.leadSourceOption = response.data.data;
          this.leadSourceOption = response.data.data.filter((item) => {
            if (
              this.userData.role == "channelpartner" &&
              item.name != "Channel Partner" &&
              item.name != "Self"
            ) {
              return false;
            }

            if (
              this.userData.role == "franchise" &&
              item.name != "Franchise" &&
              item.name != "Self"
            ) {
              return false;
            }

            if (this.userData.role == "employee" && item.name == "Franchise") {
              return false;
            }
            return true;
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getSubSource(id) {
      this.leadSourceOption.map((item) => {
        if (item.id == id) {
          this.selectedSubSource = item;
        }
      });
    },
    assigntest(data) {
      data.map((item) => {
        this.assignToOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest1(item.children);
        }
      });
    },
    assigntest1(data) {
      data.map((item) => {
        this.assignToOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest(item.children);
        }
      });
    },
  },
};
</script>
